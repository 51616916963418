<template>
  <div class="project-create">
    <div class="manage-wrapper">
      <div class="project-create-container">
        <el-tabs v-model="activeName">
          <el-tab-pane label="创建课程" name="first">
            <el-form ref="projectCreateForm" :model="form" label-width="180px" :rules="rules">

              <el-form-item label="课程名称：" prop="name" required>
                <el-input v-model="form.name" maxlength="100" show-word-limit style="width:400px"></el-input>
              </el-form-item>

              <el-form-item label="课程简介：">
                <el-input type="textarea" class="textarea" :rows="6" maxlength="2500" show-word-limit
                  v-model="form.courseExplain" resize="none"></el-input>
              </el-form-item>
              <el-form-item label="是否为精品课：" prop="boutique" required>
                <el-radio-group v-model="form.boutique">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>

              <!-- 所属项目与用户组 -->
              <UserGroup :id="Number($route.params.id)" @userGroup="changeUserGroup" :isBoutique="form.boutique">
              </UserGroup>

              <el-form-item label="授课模式：" prop="lessonsPattern" required>
                <el-radio-group v-model="form.lessonsPattern">
                  <el-radio :label="0">同步课</el-radio>
                  <el-radio :label="1">异步课</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="班型：" prop="classType" v-show="form.lessonsPattern == 0" required>
                <el-radio-group v-model="form.classType">
                  <el-radio v-for="(item,index) in classList" :key="index" :label="item.id">{{item.name}}</el-radio>
                  <!-- <el-radio :label="2">百家云大班普通课</el-radio>
                  <el-radio :label="3">百家云大班伪直播</el-radio>
                  <el-radio :label="4">百家云小班课</el-radio>
                  <el-radio :label="5">classin标准课</el-radio>
                  <el-radio :label="6">腾讯云课堂</el-radio> -->
                </el-radio-group>
              </el-form-item>

              <el-form-item v-show="form.lessonsPattern == 1" label="课程有效期：" prop="lessonsInDate">
                <el-select v-model="form.lessonsInDate" style="width:400px">
                  <el-option label="永久有效" value="1">永久有效</el-option>
                  <el-option label="一年" value="2"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="课程类型：">
                <CourseTypeSelect @courseType="changeCourseType"></CourseTypeSelect>
              </el-form-item>

              <el-form-item label="课程学科：">
                <SubjectSelect @subject="changeSubject"></SubjectSelect>
              </el-form-item>

              <el-form-item label="主题封面：">
                <UploadCover @uploadCover="handleCoverChange"></UploadCover>
              </el-form-item>

              <div class="teahcers flex-align" v-if="form.lessonsPattern == 0">
                <el-form-item label="授课老师：" prop="teacherId" required>
                  <cTeacherSelect :requestType="'teacher'" :userGroup="form.userGroupIds"
                    @teacherSelect="handleTeacherChange" />
                </el-form-item>
                <el-form-item label="助教老师：" class label-width="100px">
                  <cTeacherSelect :requestType="'assistant'" :userGroup="form.userGroupIds"
                    @teacherSelect="handleTeacherChange" />
                </el-form-item>
              </div>

              <!-- <el-form-item label="主讲教师：" class="teahcers" v-if="form.lessonsPattern == 1">
                <el-input v-model="form.createName" style="width:300px" placeholder="请填写主讲教师名称"></el-input>
              </el-form-item> -->

              <el-form-item label="课程标签：">
                <el-input v-model="form.courseLabel" maxlength="30" show-word-limit style="width:555px">
                </el-input>
              </el-form-item>

              <!-- 按钮组 -->
              <el-form-item class="el-form-item-btns" label-width="0px">
                <el-button @click="$router.push('/home/manage')">取消</el-button>
                <el-button type="primary" @click="onSubmit">确定</el-button>
              </el-form-item>
              <br />
              <br />
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>
<script>
  import cTeacherSelect from "./components/courseTeacherSelect.vue";
  export default {
    components: {
      cTeacherSelect
    },
    data() {
      var validateProjectId = (rule, value, callback) => {
        // console.log(value)
        if (value === '') {
          callback(new Error('请选择所属项目!'));
        } else {
          callback();
        }
      };
      return {
        activeName: "first",
        form: {
          name: "", //课程名称
          courseExplain: "", //课程简介
          trancheId: '', //项目分期id
          projectId: null, //项目id
          subjectId: "", //学科id
          courseTypeId: "", //课程类型id
          teacherId: "", //老师id
          assistantId: "", //助教id
          classType: 2, //班型(大班课。小班课。目前只有大班课)
          lessonsPattern: 0, //授课模式(0直播、1录播)
          lessonsInDate: "1", //课程有效期（1永久有效 2一年）
          courseLabel: "", //课程标签
          userGroupIds: null, //用户组id
          cover: "", //主题封面
          materialIds: [], //教材id
          teacherRole: 'expert', //授课老师身份
          assistantRole: 'assistant', //助教老师身份
          boutique: 0, // 是否为精品课 0否 1是
          // createName: '', //异步课 主讲教师
        },
        // 表单验证规则
        rules: {
          name: [{
            required: true,
            message: "请填写课程名称",
            trigger: "blur"
          }],
          teacherId: [{
            required: true,
            message: "请选择授课老师",
            trigger: "change"
          }],
          teacherName: [{
            required: true,
            message: "请填写教师名称",
            trigger: "change"
          }],
        },
        // 班型列表
        classList: []
      };
    },
    methods: {
      // 切换用户组
      changeUserGroup(data) {
        console.log(data);
        this.form.trancheId = data.trancheId;
        this.form.projectId = data.projectId;
        this.form.userGroupIds = data.userGroupArr;
        // console.log(this.form);
      },
      // 切换课程类型
      changeCourseType(data) {
        // console.log(data);
        this.form.courseTypeId = data;
      },
      // 切换学科
      changeSubject(data) {
        this.form.subjectId = data;
      },
      // 处理封面
      handleCoverChange(data) {
        // console.log(data)
        this.form.cover = data.md5
      },
      // 切换教师
      handleTeacherChange(data) {
        // console.log(data);
        let type = data.type === 'teacher' ? true : (data.type === 'assistant' ? false : '')
        if (type) {
          this.form.teacherId = data.teacherId
          this.form.teacherRole = data.role
        } else {
          this.form.assistantId = data.teacherId
          this.form.assistantRole = data.role
        }
        // console.log(this.form);
      },
      // 提交表单
      async commitForm() {
        // console.log(this.form);
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          background: 'rgba(255, 255, 255, 0.7)'
        });
        let resData = await this.$Api.Course.creatCourse(this.form);
        // console.log(resData);
        if (resData.code == 200) {
          loading.close();
          let type = this.form.classType
          let pattern = this.form.lessonsPattern
          // console.log(type);
          let path = pattern == 1 ? '/lesson/create/async/' : (type == 4 ? '/lesson/create/small/' :
            type == 5 ? '/lesson/create/classin/' : type == 6 ? '/lesson/create/tencent/' : '/lesson/create/large/');
          this.$router.push({
            path: `${path}${resData.data}`,
            query: {
              lessonsPattern: pattern,
              courseType: type,
              breadNumber: 4,
            }
          })
        } else {
          loading.close();
          this.$notify.error({
            title: "错误",
            message: resData.msg
          });
        }
      },
      //创建课程
      async onSubmit() {
        this.$refs['projectCreateForm'].validate((valid) => {
          // console.log('valid',valid);
          if (valid) {
            this.commitForm();
          } else {
            this.$message.error({
              title: "错误",
              message: "请正确填写数据"
            });
          }
        })
      },
      // 获取班型
      async getClassType() {
        let data = {
          projectId: this.$store.state.user.currentProject.id
        }
        let resData = await this.$Api.Course.getClassType(data)
        this.classList = resData.data
      }
    },
    created() {},
    mounted() {
      this.getClassType()
    }
  };
</script>

<style lang="less" scoped>
  @import "./less/manage-project.less";
</style>